import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { theme } from "../utils/theme";

interface TabProps {
    selected: string;
    onSelect: (tab: string) => void;
}

// const Tab = ({ onSelect, tab, selected }) => {

//     if (selected === tab) {

//     }

//     return(
//         <Box
//                 onClick={() => onSelect('Deposit History')}
//                 sx={{
//                     color: palette.primary.main,
//                     fontSize: 14,
//                     fontWeight: 800,
//                     padding: '1rem'
//                 }}

//         >
//             Deposit History
//         </Box>
//     )
// }

const Tabs = ({ selected, onSelect }: TabProps) => {
    const { palette } = theme;

    const selectHandler = (tab: string) => {
        onSelect(tab);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "400px",
                pb: "1rem",
                alignSelf: "flex-start",
            }}
        >
            <Typography
                component="a"
                sx={{
                    color:
                        selected === "Deal Purchase"
                            ? palette.primary.main
                            : palette.info.light,
                    fontSize: 14,
                    fontWeight: 800,
                    borderBottom:
                        selected === "Deal Purchase"
                            ? "2px solid #0E0E2C"
                            : "2px solid #FCFCFD",
                    cursor: "pointer",
                    "&:hover": {
                        color: "#A3A3B4",
                        borderBottom: "2px solid #A3A3B4",
                    },
                }}
                onClick={() => selectHandler("Deal Purchase")}
            >
                Deal Purchase
            </Typography>

            <Typography
                component="a"
                sx={{
                    color:
                        selected === "Deposit History"
                            ? palette.primary.main
                            : palette.info.light,
                    fontSize: 14,
                    fontWeight: 800,
                    borderBottom:
                        selected === "Deposit History"
                            ? "2px solid #0E0E2C"
                            : "2px solid #FCFCFD",
                    cursor: "pointer",
                    "&:hover": {
                        color: "#A3A3B4",
                        borderBottom: "2px solid #A3A3B4",
                    },
                }}
                onClick={() => selectHandler("Deposit History")}
            >
                Deposit History
            </Typography>

            <Typography
                component="a"
                sx={{
                    color:
                        selected === "Withdrawal History"
                            ? palette.primary.main
                            : palette.info.light,
                    fontSize: 14,
                    fontWeight: 800,
                    borderBottom:
                        selected === "Withdrawal History"
                            ? "2px solid #0E0E2C"
                            : "2px solid #FCFCFD",
                    cursor: "pointer",
                    "&:hover": {
                        color: "#A3A3B4",
                        borderBottom: "2px solid #A3A3B4",
                    },
                }}
                onClick={() => selectHandler("Withdrawal History")}
            >
                Withdrawal History
            </Typography>
        </Box>
    );
};
export default Tabs;
