import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

// interface RoundedButtonProps extends ButtonProps {
//     target?: string;
//     rel?: string;
// }

const ThemeButton = styled(Button)(({ theme }) => ({
    borderRadius: 30,
    boxShadow: 'none',
    background: 'primary.main',
    ':hover': {
        background: 'primary.main',
    }
}));

export const RoundedButton = (props: any) => {
    return <ThemeButton {...props} />;
};
