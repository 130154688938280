import { Navigate, Outlet } from "react-router-dom";
import * as tokens from "./auth";

export const ProtectedRoute = ({ redirectPath = "/login" }) => {
    if (!tokens.accessToken.get() || !tokens.refreshToken.get()) {
        return <Navigate to={redirectPath} replace />;
    }

    return <Outlet />;
};
