import { FC } from "react";
import { styled } from "@mui/material/styles";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { formatDateSlashed, percentageFormatter } from "../utils/formatters";

interface Deal {
    id: string;
    business_type: string;
    payment_frequency: string;
    product_name: string;
    funded_date: string;
    deal_status: string;
    early_payoff_rate: number;
}

interface DealInfoTableProps {
    deal: Deal;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const DealInfoTable: FC<DealInfoTableProps> = ({ deal }) => {
    return (
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table sx={{ minWidth: 650 }} aria-label="deal details">
                <TableHead>
                    <TableRow>
                        <TableCell>Deal Id</TableCell>
                        <TableCell align="right">Business Type</TableCell>
                        <TableCell align="right">Payment Frequency</TableCell>
                        <TableCell align="right">Product Name</TableCell>
                        <TableCell align="right">Funded Date</TableCell>
                        <TableCell align="right">Deal Status</TableCell>
                        <TableCell align="right">Early Payoff Rate</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <StyledTableRow
                        key={deal.id}
                        sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                        }}
                    >
                        <TableCell component="th" scope="row">
                            {deal.id.slice(0, 8)}
                        </TableCell>
                        <TableCell align="right">
                            {deal.business_type}
                        </TableCell>
                        <TableCell align="right">
                            {deal.payment_frequency}
                        </TableCell>
                        <TableCell align="right">{deal.product_name}</TableCell>
                        <TableCell align="right">
                            {formatDateSlashed(
                                deal.funded_date,
                                true
                            ).toString()}
                        </TableCell>
                        <TableCell align="right">{deal.deal_status}</TableCell>
                        <TableCell align="right">
                            {percentageFormatter(deal.early_payoff_rate)}
                        </TableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default DealInfoTable;
