import axios from "axios";
import jwt_decode from "jwt-decode";
import * as tokens from "../utils/auth";

// const baseURL = "https://lendica-gutshost-dev.herokuapp.com/api/v1";
const baseURL = "https://lendica-gutshost.herokuapp.com/api/v1";


export const axiosInstance = axios.create({ baseURL });

axios.interceptors.request.use(async (req) => {
    const accessToken = tokens.accessToken.get();
    const refreshToken = tokens.refreshToken.get();

    if (accessToken) {
        req.headers.Authorization = `Bearer ${accessToken}`;
    } else {
        return req;
    }

    const user = jwt_decode(accessToken);

    // @ts-ignore
    const isExpired = user.exp < new Date() / 1000;

    if (!isExpired) return req;

    let response;

    try {
        response = await axiosInstance.post(`${baseURL}/auth/token/refresh`, {
            refresh: refreshToken,
        });
    } catch {
        tokens.accessToken.remove();
        tokens.refreshToken.remove();
        window.location.replace(`${window.location.origin}/login`);
    }

    tokens.accessToken.set(JSON.stringify(response?.data.access));
    req.headers.Authorization = `Bearer ${response?.data.access}`;
    return req;
});
