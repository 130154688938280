import { Box, Typography, Skeleton } from "@mui/material"
import { currencyFormatter } from "../utils/formatters";

interface BalanceCardProps {
    loaded: boolean, 
    id: string,
    label: string,
    value: number
}

export const BalanceCard = ({ loaded, id, label, value } : BalanceCardProps) => {

    return(
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
            }}
            pr={id === "current-balance" ? '4rem' : 0}
        >
            <Typography
                variant="body2"
                color="primary.light"
                fontWeight={700}
            >
                {label}
            </Typography>

            <div id={id}>
                <Typography
                    variant={id === 'total-balance' ?  "h3" : "h4"}
                    color="primary.main"
                    fontWeight={800}
                    data-testid={id}
                >
                    {loaded && (typeof value === 'number') ? 
                        currencyFormatter(value) :
                        <Skeleton data-testid={'skeleton'} width={250} height={80}/>
                    }
                </Typography>
            </div>
        </Box>
    )
}