import Box from "@mui/material/Box";
import { Logo } from "./Logo";
// import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
// import { IconButton } from "@mui/material";
// import { logout } from "../api";
import PopupMenuButton from "./PopupMenu/PopupMenuButton";

const Header = () => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                position: "fixed",
                top: 0,
                width: "100vw",
                height: "48px",
                padding: "1rem 1.5rem",
                justifyContent: "space-between",
                alignItems: "center",
                boxSizing: "border-box",
                zIndex: 1100,
                background: "#FCFCFD",
                // boxShadow:
                //     "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;",
            }}
        >
            <Logo dark height={19} />

            {/* <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    top: 0,
                    width: '1024px',
                    height: '100%',
                    padding: '1rem 0rem',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                    zIndex: 3,
                }}
            >
            </Box> */}

            <Box style={{ position: 'relative' }}>
                <PopupMenuButton/>
            </Box>
            
        </Box>
    );
};
export default Header;
