import { IconButton } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const ProfileIcon = ({ name, clickHandler }) => {
    if (clickHandler) {
        return (
            <IconButton onClick={clickHandler}>
                <AccountCircleIcon />
            </IconButton>
            // <div className="profile-icon" onClick={clickHandler}>{name}</div>
        );
    } else {
        return <div className="profile-icon">{name}</div>;
    }
};

export default ProfileIcon;
