// import ProfileIcon from '../ProfileIcon'
import { Typography } from '@mui/material';
import { useContext } from 'react'
import AuthContext from '../../context/AuthContext';
import { useData } from '../../context/DataContext'

const PopupMenu = () => {
    let { logout } = useContext(AuthContext)
    let { investorSummary } = useData()
    
    return(
        <div className='popup-menu'>
            <div className="popup-menu-header">
                {/* <ProfileIcon name={"JM"}/> */}
                <div className="profile-info">
                    <Typography variant='h4' fontWeight={550} fontSize={16}>{investorSummary.company_name}</Typography>
                </div>
            </div>

            <div className="popup-menu-footer" onClick={logout}>
                Sign out
            </div>
        </div>
    )
}
export default PopupMenu