import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { AuthProvider } from "./context/AuthContext";
import { DataProvider } from "./context/DataContext";
import { Home } from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import { ProtectedRoute } from "./utils/ProtectedRoute";
import { theme } from "./utils/theme";

function App() {
    return (
        <Router>
            <ThemeProvider theme={theme}>
                <AuthProvider>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route element={<ProtectedRoute />}>
                            <Route
                                path="/"
                                element={
                                    <DataProvider>
                                        <Home />
                                    </DataProvider>
                                }
                            />
                        </Route>
                    </Routes>
                </AuthProvider>
            </ThemeProvider>
        </Router>
    );
}

export default App;
