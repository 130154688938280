// import React, { FC } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import PasswordTextField from '../../components/PasswordTextField';
import { RoundedButton } from '../../components/RoundedButton';
import { useFormik } from 'formik';
import { useContext } from 'react';
import * as yup from 'yup';
import AuthContext from '../../context/AuthContext';
import { Logo } from '../../components/Logo';

const Login = () => {

    const { login } = useContext(AuthContext);

    const validationSchema = yup.object({
        email: yup
            .string()
            .email('Enter a valid email')
            .required('Email is required'),
        password: yup
            .string()
            .min(8, 'Password should be of minimum 8 characters length')
            .required('Password is required'),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: ({ email, password }) => login(email, password),
    });
   
    return (
        <Box
            component="form"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems='center'
            flex={1}
            // px={4}
            // background="#FFF"
            pb={4}
            height='100vh'
            width="100vw"
            boxSizing='border-box'
            onSubmit={formik.handleSubmit}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '400px',
                    height: '100%',
                    // background: '#000'
                }}
            >
                <Box mb={6}>
                    <Logo dark={true} height={22}/>
                </Box>
                <Typography variant="h6" pb={4}>
                    Sign in to Lendica Investor Portal
                </Typography>
                <TextField
                    name="email"
                    label="Email"
                    // className={classes.formControl}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={!!(formik.touched.email && formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email ? formik.errors.email : ' '}
                />

                <PasswordTextField
                    id="password"
                    name="password"
                    label="Password"
                    autoComplete="on"
                    // className={classes.formControl}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={!!(formik.touched.password && formik.errors.password)}
                    helperText={
                        formik.touched.password && formik.errors.password ? formik.errors.password : ' '
                    }
                />

                {/* <Typography variant="body2" pb={3}>
                    <Link href="/forgotPassword">Forgot your password?</Link>
                </Typography> */}

                <RoundedButton
                    background="palette.primary.main"
                    variant="contained"
                    type="submit"
                    // className={classes.button}
                    id="portal-log-in-button"
                >
                    log in
                </RoundedButton>

                {/* <Box display="flex" alignItems="center" pt={3}>
                    <Typography variant="body2">Not on Lendica?&nbsp;</Typography>
                    <Typography variant="body2">
                        <Link href="/signup">Create an account</Link>
                    </Typography>
                </Box> */}
            </Box>
        </Box>
    );
};

export default Login
