import { useData } from "../../context/DataContext";
import EnhancedTable from "../../components/DealPurchaseTable";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Header from "../../components/Header";
import { useState } from "react";
import Tabs from "../../components/Tabs";
import DealInfoTable from "../../components/DealInfoTable";
import { theme } from "../../utils/theme";
import WithdrawalHistoryTable from "../../components/WithdrawalHistoryTable";
import DepositHistoryTable from "../../components/DepositHistoryTable";
import { Skeleton } from "@mui/material";
import { BalanceCard } from "../../components/BalanceCard";

export const Home = () => {
    const {
        loaded,
        purchaseDetails,
        investorSummary,
        withdrawalHistory,
        depositHistory,
    } = useData();
    const { palette } = theme;

    const [tab, setTab] = useState("Deal Purchase");
    const [selectedDeal, setSelectedDeal] = useState<any | null>(null);

    const onSelect = (tab: string) => {
        setTab(tab);
        setSelectedDeal(null);
    };
    
    const onPurchaseSelect = (id: string) => {
        let deal = purchaseDetails.find((x) => x.id === id)?.deal;

        if (deal) {
            setSelectedDeal(deal);
        } else {
            setSelectedDeal(null);
        }
    };

    let total_balance = investorSummary.current_total_balance < 0 ? 0 : investorSummary.current_total_balance
    let total_cash_balance = investorSummary.current_cash_balance < 0 ? 0 : investorSummary.current_cash_balance
    let total_deal_balance = investorSummary.current_deal_balance < 0 ? 0 : investorSummary.current_deal_balance

    return (
        <Box
            sx={{
                height: "fit-content",
                width: "100vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 13,
                marginBottom: 10,
            }}
        >
            <Header />
            <Box
                sx={{
                    height: "100%",
                    maxWidth: "1024px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        maxWidth: "1024px",
                        minWidth: "600px",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                    mb={10}
                >
                    <BalanceCard id="total-balance" value={total_balance} label="Total Balance" loaded={loaded}/>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                        }}
                    >
                        <BalanceCard id="current-balance" value={total_deal_balance} label="Deal Balance" loaded={loaded}/>
                        <BalanceCard id="cash-balance" value={total_cash_balance} label="Cash Balance" loaded={loaded}/>
                    </Box>
                </Box>
                <Tabs selected={tab} onSelect={onSelect} />

                {tab === "Deal Purchase" && loaded && (
                    <EnhancedTable
                        data={purchaseDetails}
                        onClick={onPurchaseSelect}
                    />
                )}
                {tab === "Withdrawal History" && loaded && (
                    <WithdrawalHistoryTable data={withdrawalHistory} />
                )}
                {tab === "Deposit History" && loaded && (
                    <DepositHistoryTable data={depositHistory} />
                )}

                {!loaded && 
                    <Box
                        sx={{
                            width: '100%',
                            marginTop: '0.5rem'
                        }}
                    >
                        <Skeleton width={'100%'} height={60}/>
                        <Skeleton width={'100%'} height={60}/>
                        <Skeleton width={'100%'} height={60}/>
                        <Skeleton width={'100%'} height={60}/>
                        <Skeleton width={'100%'} height={60}/>
                        <Skeleton width={'100%'} height={60}/>
                    </Box>
                }

                {selectedDeal && tab === "Deal Purchase" && (
                    <Box
                        sx={{
                            width: "1024px",
                        }}
                    >
                        <Typography
                            sx={{
                                width: "fit-content",
                                color: palette.primary.main,
                                fontSize: 14,
                                fontWeight: 800,
                                borderBottom: "2px solid black",
                                mb: 2,
                            }}
                        >
                            Deal Info
                        </Typography>

                        <DealInfoTable deal={selectedDeal} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};
