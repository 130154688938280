import { parse, format } from 'date-fns';

export const currencyFormatter = (value, omitCents = false) => {
    try {
        const result = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(value);
        if (omitCents) {
            return result.split('.')[0];
        }
        return result;
    } catch (e) {
        return value;
    }
};

export const percentageFormatter = (value, precision = 2) => {
    try {
        return Number(value * 100).toFixed(precision) + '%';
    } catch (e) {
        return value;
    }
};

export const formatDateSlashed = (date, year = true) => {
    let formattedDate = parse(date, 'yyyy-MM-dd', new Date());

    if (year) {
        formattedDate = format(formattedDate, 'M/d/yy');
    } else {
        formattedDate = format(formattedDate, 'M/d');
    }
    return formattedDate;
};