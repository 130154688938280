import {
    FC,
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from "react";
import { api } from "../api";
import { InvestorSummary, PurchaseDetail } from "../utils/types";
// import AuthContext from "./AuthContext";
// import { accessToken } from "../utils/auth";

const investorSummary = {
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    company_name: "",
    total_number_of_active_deals: 0,
    total_purchase: 0,
    current_cash_balance: 0,
    total_deposit: 0,
    total_withdrawal: 0,
    historical_return: 0,
    current_deal_balance: 0,
    current_total_balance: 0,
    deposit_history: [],
    withdrawal_history: [],
};

interface DataContextInterface {
    loaded: boolean;
    investorSummary: InvestorSummary;
    purchaseDetails: PurchaseDetail[];
    depositHistory: any;
    withdrawalHistory: any;
}

const initialState = {
    loaded: false,
    investorSummary: {
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        company_name: "",
        total_number_of_active_deals: 0,
        total_purchase: 0,
        current_cash_balance: 0,
        total_deposit: 0,
        total_withdrawal: 0,
        historical_return: 0,
        current_deal_balance: 0,
        current_total_balance: 0,
        deposit_history: [],
        withdrawal_history: [],
    },
    purchaseDetails: [],
    depositHistory: [],
    withdrawalHistory: [],
};

// const DataContext = createContext<DataContextInterface>(initialState);
const DataContext = createContext<DataContextInterface>(initialState);

export const DataProvider: FC<{ children: ReactNode }> = ({ children }) => {
    // const { authenticated } = useAuth()
    // const { loading } = useAuth();

    const [state, setState] = useState({
        loaded: false,
        investorSummary: investorSummary,
        purchaseDetails: [],
        depositHistory: [],
        withdrawalHistory: [],
    });

    // useEffect(() => {
    //     const getSummaryData = async () => {
    //         Promise.all([
    //             api.getPurchases(),
    //             api.getDepositHistory(),
    //             api.getWithdrawalHistory(),
    //             api.getInvestorSummary(),
    //         ]).then((res) => {
    //             setState({
    //                 loaded: true,
    //                 investorSummary: res[3],
    //                 purchaseDetails: res[0],
    //                 depositHistory: res[1],
    //                 withdrawalHistory: res[2],
    //             });
    //         });
    //     };
    //     if (!loading || accessToken.get()) {
    //         (async () => {
    //             await getSummaryData();
    //         })();
    //     }
    // }, [loading]);

    useEffect(() => {
        const getSummaryData = async () => {
            Promise.all([
                api.getPurchases(),
                api.getDepositHistory(),
                api.getWithdrawalHistory(),
                api.getInvestorSummary(),
            ]).then((res) => {
                setState({
                    loaded: true,
                    investorSummary: res[3],
                    purchaseDetails: res[0],
                    depositHistory: res[1],
                    withdrawalHistory: res[2],
                });
            });
        };
        getSummaryData();
    }, []);

    return (
        <DataContext.Provider value={state}>{children}</DataContext.Provider>
    );
};

export const useData = () => {
    return useContext(DataContext);
};
