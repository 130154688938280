import axios from "axios";
import { axiosInstance } from "./axiosInstance";
import { refreshToken } from "../utils/auth";

// const baseURL = "https://lendica-gutshost-dev.herokuapp.com/api/v1";
const baseURL = "https://lendica-gutshost.herokuapp.com/api/v1";

export const login = async (params) => {
    const response = await axiosInstance.post("/auth/token", params);
    return response.data;
};

export const signUp = async (params) => {
    const response = await axiosInstance.post("/auth/register", params);
    return response.data;
};

export const logout = async () => {
    await axios.post( `${baseURL}/auth/logout`, { refresh_token: refreshToken.get() }
    );
};

export const forgotPassword = async (email) => {
    await axiosInstance.post("/auth/forgot_password", { email });
};

export const resetPassword = async (
    username,
    oldPassword,
    newPassword,
    confirmedPassword
) => {
    const response = await axiosInstance.post("/auth/change_password", {
        username,
        oldPassword,
        newPassword,
        confirmedPassword,
    });
    return response.data;
}

export const getInvestorSummary = async () => {
    const response = await axios.get(`${baseURL}/investor/summary`);
    return response.data
}


export const getPurchases = async () => {
    const response = await axios.get(`${baseURL}/investor/purchases`);
    return response.data
}

export const getWithdrawalHistory = async () => {
    const response = await axios.get(`${baseURL}/investor/withdrawal_history`);
    return response.data
}

export const getDepositHistory = async () => {
    const response = await axios.get(`${baseURL}/investor/deposit_history`);
    return response.data
}


export const api = {
    login, 
    signUp, 
    logout, 
    forgotPassword,
    resetPassword, 
    getInvestorSummary,
    getPurchases, 
    getWithdrawalHistory,
    getDepositHistory
}
