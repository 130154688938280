import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface ThemeOptions {
    }
}

export const theme = createTheme({
    palette: {
        primary: { main: "#0E0F2C", light: "#6E6E80" },
        secondary: { main: "#2E3192", light: "#EDEDF4" },
        success: { main: "#58A10E" },
        error: { main: "#ED7A56" },
        warning: { main: "#FAAF6D" },
        action: { disabled: "#8C8CA1" },
        info: {
            main: "#4A4A68",
            dark: "#3B3B53",
            light: "#9292A4",
        },
        text: {
            primary: "#0E0E2C",
            secondary: "#4A4A68",
            disabled: "#8C8CA1",
        },
        background: {
            paper: "#FCFCFD",
            default: "#F8F8FB",
        },
    },
    typography: {
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            '"Helvetica Neue"',
            "sans-serif",
        ].join(","),
        fontWeightRegular: 300,
        button: { fontWeight: 400 },
        subtitle1: {
            fontWeight: 600,
        },
        subtitle2: {
            fontWeight: 300,
        },
        h4: {
            fontWeight: 700,
        },
        h5: {
            fontWeight: 200,
        },
        caption: {
            color: "#8C8CA1",
        },
        overline: {
            color: "#8C8CA1",
            fontSize: "0.6875rem",
        },
    },
});
